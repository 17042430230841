import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const GenerateInvoice = lazy(() => import('../../container/invoiceBilling/generateInvoice'));
const ViewInvoice = lazy(() => import('../../container/invoiceBilling/viewInvoice'));
const ViewValidity = lazy(() => import('../../container/invoiceBilling/viewValidity'));
const Packages = lazy(() => import('../../container/invoiceBilling/packages'));
const NotFound = lazy(() => import('../../container/pages/404'));

function InvoiceRoutes() {
  return (
    <Routes>
      {/* <Route index element={<Client />} /> */}
      <Route path="generate" element={<GenerateInvoice />} />
      <Route path="view" element={<ViewInvoice />} />
      <Route path="validity" element={<ViewValidity />} />
      <Route path="packages" element={<Packages />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default InvoiceRoutes;
