import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const Onboarding = lazy(() => import('../../container/client/Onboarding'));
const ClientView = lazy(() => import('../../container/client/ClientView'));
const ClientDetails = lazy(() => import('../../container/client/ClientDetails'));
const NotFound = lazy(() => import('../../container/pages/404'));

function ClientRoutes() {
  return (
    <Routes>
      {/* <Route index element={<Client />} /> */}
      <Route path="onboarding" element={<Onboarding />} />
      <Route path="view" element={<ClientView />} />
      <Route path="details" element={<ClientDetails />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default ClientRoutes;
