import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const Create = lazy(() => import('../../container/employee/CreateEmployee'));
const EmployeeView = lazy(() => import('../../container/employee/EmployeeView'));
const EmployeeDetails = lazy(() => import('../../container/employee/EmployeeDetails'));
const NotFound = lazy(() => import('../../container/pages/404'));

function EmployeeRoutes() {
  return (
    <Routes>
      {/* <Route index element={<Employee />} /> */}
      <Route path="create/*" element={<Create />} />
      <Route path="view" element={<EmployeeView />} />
      <Route path="details" element={<EmployeeDetails />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default EmployeeRoutes;
